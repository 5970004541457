.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation for wave effect */
.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background-size: 100rem 11rem;
  background-repeat: repeat-x;
}

/* Corrected paths for wave image */
.wave1 {
  background-image: url('./Assets/wave.png'); /* Updated to relative path */
  animation: moveWave 8s linear infinite;
}

.wave2 {
  background-image: url('./Assets/wave.png'); /* Updated to relative path */
  animation: moveWave 6s linear infinite reverse; /* Reverse animation */
  opacity: 0.3;
}

.wave3 {
  background-image: url('./Assets/wave.png'); /* Updated to relative path */
  animation: moveWave 4s linear infinite;
  opacity: 0.5;
}

/* Keyframes for wave movement */
@keyframes moveWave {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 100rem;
  }
}
